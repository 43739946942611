import React from "react";
import Pagination from "react-js-pagination";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import styled from "styled-components";
import "./pagination.css";

const StyledFontAwesomeIcon = styled(FontAwesomeIcon)`
  font-size: 25px !important;
`;
const NoPagination = styled.div`
  background-color: #414141;
  border-radius:  0 0 8px 8px;
  height: 10px;
  
`;

const PaginationReact = ({ ...props }) => {
  return (
    <>
      {props.totalItemsCount > props.itemsCountPerPage && (
        <Pagination
          linkClassFirst="arrows"
          linkClassPrev="arrows"
          linkClassNext="arrows"
          linkClassLast="arrows"
          nextPageText={<StyledFontAwesomeIcon icon={faAngleRight} />}
          lastPageText={<StyledFontAwesomeIcon icon={faAngleDoubleRight} />}
          firstPageText={<StyledFontAwesomeIcon icon={faAngleDoubleLeft} />}
          prevPageText={<StyledFontAwesomeIcon icon={faAngleLeft} />}
          itemClassLast="last"
          itemClassNext="next"
          itemClass="item"
          {...props}
        />
      )}
      {props.totalItemsCount <= props.itemsCountPerPage && <NoPagination></NoPagination>}
    </>
  );
};
export default PaginationReact;
