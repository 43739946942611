import React from "react";
import styled from "styled-components";

const Styled = styled.div`
  color: #0a54a5;
  font-weight: bold;
  font-size: 21px;
  line-height: 25px;
  text-align: center;
  margin: 15px 30px;
  @media only screen and (max-width: 900px) {
    margin: 15px 10px;
    font-size: 17px;
    line-height: 20px;
  }
`;
const PageMessage = ({ ...props }) => {
  return <Styled {...props}>{props.children}</Styled>;
};

export default PageMessage;
