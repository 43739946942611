import React from "react";
import styled from "styled-components";
import Input from "../atoms/input";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { Row, Col } from "reactstrap";
import FilterLabel from "../atoms/filterLabel";

const Container = styled(Row)`
  position: relative;
`;
const ClearButton = styled.div`
  position: absolute;
  cursor: pointer;
  bottom: 0px;
  width: 32px;
  right: 24px;
  font-size: 26px;
  color: #b1b1b1;
`;

export default class TextBoxFilter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      isUpper: this.props.isUpper,
      items: [],
    };
    this.clear = this.clear.bind(this);
  }
  clear() {
    this.props.refresh("");
    this.setState({ value: "" });
  }
  handleUserInput = (event) => {
    if (event.target) {
      const name = event.target.name;
      var value = event.target.value;

      if (this.props.isUpper) {
        value = value.toUpperCase();
      }
      this.props.refresh(value.trim());

      this.setState({ [name]: value.trim() });
    }
  };
  render() {
    return (
      <>
        <Container>
          <Col xs="12">
            <FilterLabel>{this.props.title}</FilterLabel>
          </Col>
          <Col xs="12">
            <Input style={{border: this.state.value ? "3px solid #64259e" : "1px solid #bdbdbd"}} disabled={this.props.disabled} placeholder={"Search " + this.props.title} onChange={this.handleUserInput} name="value" className="noClear" value={this.state.value}></Input>
          </Col>
          <ClearButton onClick={this.clear}>{this.state.value && <FontAwesomeIcon icon={faTimes}></FontAwesomeIcon>}</ClearButton>
        </Container>
      </>
    );
  }
}
