export const Countries = [
    { key: 1, value: "Afghanistan", label: "Afghanistan" },
    { key: 2, value: "Åland Islands", label: "Åland Islands" },
    { key: 3, value: "Albania", label: "Albania" },
    { key: 4, value: "Algeria", label: "Algeria" },
    { key: 5, value: "American Samoa", label: "American Samoa" },
    { key: 6, value: "Andorra", label: "Andorra" },
    { key: 7, value: "Angola", label: "Angola" },
    { key: 8, value: "Anguilla", label: "Anguilla" },
    { key: 9, value: "Antarctica", label: "Antarctica" },
    { key: 10, value: "Antigua and Barbuda", label: "Antigua and Barbuda" },
    { key: 11, value: "Argentina", label: "Argentina" },
    { key: 12, value: "Armenia", label: "Armenia" },
    { key: 13, value: "Aruba", label: "Aruba" },
    { key: 14, value: "Australia", label: "Australia" },
    { key: 15, value: "Austria", label: "Austria" },
    { key: 16, value: "Azerbaijan", label: "Azerbaijan" },
    { key: 17, value: "Bahamas", label: "Bahamas" },
    { key: 18, value: "Bahrain", label: "Bahrain" },
    { key: 19, value: "Bangladesh", label: "Bangladesh" },
    { key: 20, value: "Barbados", label: "Barbados" },
    { key: 21, value: "Belarus", label: "Belarus" },
    { key: 22, value: "Belgium", label: "Belgium" },
    { key: 23, value: "Belize", label: "Belize" },
    { key: 24, value: "Benin", label: "Benin" },
    { key: 25, value: "Bermuda", label: "Bermuda" },
    { key: 26, value: "Bhutan", label: "Bhutan" },
    { key: 27, value: "Bolivia", label: "Bolivia" },
    { key: 28, value: "Bosnia and Herzegovina", label: "Bosnia and Herzegovina" },
    { key: 29, value: "Botswana", label: "Botswana" },
    { key: 30, value: "Bouvet Island", label: "Bouvet Island" },
    { key: 31, value: "Brazil", label: "Brazil" },
    { key: 32, value: "British Indian Ocean Territory", label: "British Indian Ocean Territory" },
    { key: 33, value: "Brunei Darussalam", label: "Brunei Darussalam" },
    { key: 34, value: "Bulgaria", label: "Bulgaria" },
    { key: 35, value: "Burkina Faso", label: "Burkina Faso" },
    { key: 36, value: "Burundi", label: "Burundi" },
    { key: 37, value: "Cambodia", label: "Cambodia" },
    { key: 38, value: "Cameroon", label: "Cameroon" },
    { key: 39, value: "Canada", label: "Canada" },
    { key: 40, value: "Cape Verde", label: "Cape Verde" },
    { key: 41, value: "Cayman Islands", label: "Cayman Islands" },
    { key: 42, value: "Central African Republic", label: "Central African Republic" },
    { key: 43, value: "Chad", label: "Chad" },
    { key: 44, value: "Chile", label: "Chile" },
    { key: 45, value: "China", label: "China" },
    { key: 46, value: "Christmas Island", label: "Christmas Island" },
    { key: 47, value: "Cocos (Keeling) Islands", label: "Cocos (Keeling) Islands" },
    { key: 48, value: "Colombia", label: "Colombia" },
    { key: 49, value: "Comoros", label: "Comoros" },
    { key: 50, value: "Congo", label: "Congo" },
    { key: 51, value: "Congo, The Democratic Republic of the", label: "Congo, The Democratic Republic of the" },
    { key: 52, value: "Cook Islands", label: "Cook Islands" },
    { key: 53, value: "Costa Rica", label: "Costa Rica" },
    { key: 54, value: "Cote D'Ivoire", label: "Cote D'Ivoire" },
    { key: 55, value: "Croatia", label: "Croatia" },
    { key: 56, value: "Cuba", label: "Cuba" },
    { key: 57, value: "Cyprus", label: "Cyprus" },
    { key: 58, value: "Czech Republic", label: "Czech Republic" },
    { key: 59, value: "Denmark", label: "Denmark" },
    { key: 60, value: "Djibouti", label: "Djibouti" },
    { key: 61, value: "Dominica", label: "Dominica" },
    { key: 62, value: "Dominican Republic", label: "Dominican Republic" },
    { key: 63, value: "Ecuador", label: "Ecuador" },
    { key: 64, value: "Egypt", label: "Egypt" },
    { key: 65, value: "El Salvador", label: "El Salvador" },
    { key: 66, value: "Equatorial Guinea", label: "Equatorial Guinea" },
    { key: 67, value: "Eritrea", label: "Eritrea" },
    { key: 68, value: "Estonia", label: "Estonia" },
    { key: 69, value: "Ethiopia", label: "Ethiopia" },
    { key: 70, value: "Falkland Islands (Malvinas)", label: "Falkland Islands (Malvinas)" },
    { key: 71, value: "Faroe Islands", label: "Faroe Islands" },
    { key: 72, value: "Fiji", label: "Fiji" },
    { key: 73, value: "Finland", label: "Finland" },
    { key: 74, value: "France", label: "France" },
    { key: 75, value: "French Guiana", label: "French Guiana" },
    { key: 76, value: "French Polynesia", label: "French Polynesia" },
    { key: 77, value: "French Southern Territories", label: "French Southern Territories" },
    { key: 78, value: "Gabon", label: "Gabon" },
    { key: 79, value: "Gambia", label: "Gambia" },
    { key: 80, value: "Georgia", label: "Georgia" },
    { key: 81, value: "Germany", label: "Germany" },
    { key: 82, value: "Ghana", label: "Ghana" },
    { key: 83, value: "Gibraltar", label: "Gibraltar" },
    { key: 84, value: "Greece", label: "Greece" },
    { key: 85, value: "Greenland", label: "Greenland" },
    { key: 86, value: "Grenada", label: "Grenada" },
    { key: 87, value: "Guadeloupe", label: "Guadeloupe" },
    { key: 88, value: "Guam", label: "Guam" },
    { key: 89, value: "Guatemala", label: "Guatemala" },
    { key: 90, value: "Guernsey", label: "Guernsey" },
    { key: 91, value: "Guinea", label: "Guinea" },
    { key: 92, value: "Guinea-Bissau", label: "Guinea-Bissau" },
    { key: 93, value: "Guyana", label: "Guyana" },
    { key: 94, value: "Haiti", label: "Haiti" },
    { key: 95, value: "Heard Island and Mcdonald Islands", label: "Heard Island and Mcdonald Islands" },
    { key: 96, value: "Holy See (Vatican City State)", label: "Holy See (Vatican City State)" },
    { key: 97, value: "Honduras", label: "Honduras" },
    { key: 98, value: "Hong Kong", label: "Hong Kong" },
    { key: 99, value: "Hungary", label: "Hungary" },
    { key: 100, value: "Iceland", label: "Iceland" },
    { key: 101, value: "India", label: "India" },
    { key: 102, value: "Indonesia", label: "Indonesia" },
    { key: 103, value: "Iran, Islamic Republic Of", label: "Iran, Islamic Republic Of" },
    { key: 104, value: "Iraq", label: "Iraq" },
    { key: 105, value: "Ireland", label: "Ireland" },
    { key: 106, value: "Isle of Man", label: "Isle of Man" },
    { key: 107, value: "Israel", label: "Israel" },
    { key: 108, value: "Italy", label: "Italy" },
    { key: 109, value: "Jamaica", label: "Jamaica" },
    { key: 110, value: "Japan", label: "Japan" },
    { key: 111, value: "Jersey", label: "Jersey" },
    { key: 112, value: "Jordan", label: "Jordan" },
    { key: 113, value: "Kazakhstan", label: "Kazakhstan" },
    { key: 114, value: "Kenya", label: "Kenya" },
    { key: 115, value: "Kiribati", label: "Kiribati" },
    { key: 116, value: "Korea, Democratic People'S Republic of", label: "Korea, Democratic People'S Republic of" },
    { key: 117, value: "Korea, Republic of", label: "Korea, Republic of" },
    { key: 118, value: "Kuwait", label: "Kuwait" },
    { key: 119, value: "Kyrgyzstan", label: "Kyrgyzstan" },
    { key: 120, value: "Lao People'S Democratic Republic", label: "Lao People'S Democratic Republic" },
    { key: 121, value: "Latvia", label: "Latvia" },
    { key: 122, value: "Lebanon", label: "Lebanon" },
    { key: 123, value: "Lesotho", label: "Lesotho" },
    { key: 124, value: "Liberia", label: "Liberia" },
    { key: 125, value: "Libyan Arab Jamahiriya", label: "Libyan Arab Jamahiriya" },
    { key: 126, value: "Liechtenstein", label: "Liechtenstein" },
    { key: 127, value: "Lithuania", label: "Lithuania" },
    { key: 128, value: "Luxembourg", label: "Luxembourg" },
    { key: 129, value: "Macao", label: "Macao" },
    { key: 130, value: "Macedonia, The Former Yugoslav Republic of", label: "Macedonia, The Former Yugoslav Republic of" },
    { key: 131, value: "Madagascar", label: "Madagascar" },
    { key: 132, value: "Malawi", label: "Malawi" },
    { key: 133, value: "Malaysia", label: "Malaysia" },
    { key: 134, value: "Maldives", label: "Maldives" },
    { key: 135, value: "Mali", label: "Mali" },
    { key: 136, value: "Malta", label: "Malta" },
    { key: 137, value: "Marshall Islands", label: "Marshall Islands" },
    { key: 138, value: "Martinique", label: "Martinique" },
    { key: 139, value: "Mauritania", label: "Mauritania" },
    { key: 140, value: "Mauritius", label: "Mauritius" },
    { key: 141, value: "Mayotte", label: "Mayotte" },
    { key: 142, value: "Mexico", label: "Mexico" },
    { key: 143, value: "Micronesia, Federated States of", label: "Micronesia, Federated States of" },
    { key: 144, value: "Moldova, Republic of", label: "Moldova, Republic of" },
    { key: 145, value: "Monaco", label: "Monaco" },
    { key: 146, value: "Mongolia", label: "Mongolia" },
    { key: 147, value: "Montserrat", label: "Montserrat" },
    { key: 148, value: "Morocco", label: "Morocco" },
    { key: 149, value: "Mozambique", label: "Mozambique" },
    { key: 150, value: "Myanmar", label: "Myanmar" },
    { key: 151, value: "Namibia", label: "Namibia" },
    { key: 152, value: "Nauru", label: "Nauru" },
    { key: 153, value: "Nepal", label: "Nepal" },
    { key: 154, value: "Netherlands", label: "Netherlands" },
    { key: 155, value: "Netherlands Antilles", label: "Netherlands Antilles" },
    { key: 156, value: "New Caledonia", label: "New Caledonia" },
    { key: 157, value: "New Zealand", label: "New Zealand" },
    { key: 158, value: "Nicaragua", label: "Nicaragua" },
    { key: 159, value: "Niger", label: "Niger" },
    { key: 160, value: "Nigeria", label: "Nigeria" },
    { key: 161, value: "Niue", label: "Niue" },
    { key: 162, value: "Norfolk Island", label: "Norfolk Island" },
    { key: 163, value: "Northern Mariana Islands", label: "Northern Mariana Islands" },
    { key: 164, value: "Norway", label: "Norway" },
    { key: 165, value: "Oman", label: "Oman" },
    { key: 166, value: "Pakistan", label: "Pakistan" },
    { key: 167, value: "Palau", label: "Palau" },
    { key: 168, value: "Palestinian Territory, Occupied", label: "Palestinian Territory, Occupied" },
    { key: 169, value: "Panama", label: "Panama" },
    { key: 170, value: "Papua New Guinea", label: "Papua New Guinea" },
    { key: 171, value: "Paraguay", label: "Paraguay" },
    { key: 172, value: "Peru", label: "Peru" },
    { key: 173, value: "Philippines", label: "Philippines" },
    { key: 174, value: "Pitcairn", label: "Pitcairn" },
    { key: 175, value: "Poland", label: "Poland" },
    { key: 176, value: "Portugal", label: "Portugal" },
    { key: 177, value: "Puerto Rico", label: "Puerto Rico" },
    { key: 178, value: "Qatar", label: "Qatar" },
    { key: 179, value: "Reunion", label: "Reunion" },
    { key: 180, value: "Romania", label: "Romania" },
    { key: 181, value: "Russian Federation", label: "Russian Federation" },
    { key: 182, value: "RWANDA", label: "RWANDA" },
    { key: 183, value: "Saint Helena", label: "Saint Helena" },
    { key: 184, value: "Saint Kitts and Nevis", label: "Saint Kitts and Nevis" },
    { key: 185, value: "Saint Lucia", label: "Saint Lucia" },
    { key: 186, value: "Saint Pierre and Miquelon", label: "Saint Pierre and Miquelon" },
    { key: 187, value: "Saint Vincent and the Grenadines", label: "Saint Vincent and the Grenadines" },
    { key: 188, value: "Samoa", label: "Samoa" },
    { key: 189, value: "San Marino", label: "San Marino" },
    { key: 190, value: "Sao Tome and Principe", label: "Sao Tome and Principe" },
    { key: 191, value: "Saudi Arabia", label: "Saudi Arabia" },
    { key: 192, value: "Senegal", label: "Senegal" },
    { key: 193, value: "Serbia and Montenegro", label: "Serbia and Montenegro" },
    { key: 194, value: "Seychelles", label: "Seychelles" },
    { key: 195, value: "Sierra Leone", label: "Sierra Leone" },
    { key: 196, value: "Singapore", label: "Singapore" },
    { key: 197, value: "Slovakia", label: "Slovakia" },
    { key: 198, value: "Slovenia", label: "Slovenia" },
    { key: 199, value: "Solomon Islands", label: "Solomon Islands" },
    { key: 200, value: "Somalia", label: "Somalia" },
    { key: 201, value: "South Africa", label: "South Africa" },
    { key: 202, value: "South Georgia and the South Sandwich Islands", label: "South Georgia and the South Sandwich Islands" },
    { key: 203, value: "Spain", label: "Spain" },
    { key: 204, value: "Sri Lanka", label: "Sri Lanka" },
    { key: 205, value: "Sudan", label: "Sudan" },
    { key: 206, value: "Surivalue", label: "Surivalue" },
    { key: 207, value: "Svalbard and Jan Mayen", label: "Svalbard and Jan Mayen" },
    { key: 208, value: "Swaziland", label: "Swaziland" },
    { key: 209, value: "Sweden", label: "Sweden" },
    { key: 210, value: "Switzerland", label: "Switzerland" },
    { key: 211, value: "Syrian Arab Republic", label: "Syrian Arab Republic" },
    { key: 212, value: "Taiwan, Province of China", label: "Taiwan, Province of China" },
    { key: 213, value: "Tajikistan", label: "Tajikistan" },
    { key: 214, value: "Tanzania", label: "Tanzania" },
    { key: 215, value: "Thailand", label: "Thailand" },
    { key: 216, value: "Timor-Leste", label: "Timor-Leste" },
    { key: 217, value: "Togo", label: "Togo" },
    { key: 218, value: "Tokelau", label: "Tokelau" },
    { key: 219, value: "Tonga", label: "Tonga" },
    { key: 220, value: "Trinidad and Tobago", label: "Trinidad and Tobago" },
    { key: 221, value: "Tunisia", label: "Tunisia" },
    { key: 222, value: "Turkey", label: "Turkey" },
    { key: 223, value: "Turkmenistan", label: "Turkmenistan" },
    { key: 224, value: "Turks and Caicos Islands", label: "Turks and Caicos Islands" },
    { key: 225, value: "Tuvalu", label: "Tuvalu" },
    { key: 226, value: "Uganda", label: "Uganda" },
    { key: 227, value: "Ukraine", label: "Ukraine" },
    { key: 228, value: "United Arab Emirates", label: "United Arab Emirates" },
    { key: 229, value: "United Kingdom", label: "United Kingdom" },
    { key: 230, value: "United States", label: "United States" },
    { key: 231, value: "United States Minor Outlying Islands", label: "United States Minor Outlying Islands" },
    { key: 232, value: "Uruguay", label: "Uruguay" },
    { key: 233, value: "Uzbekistan", label: "Uzbekistan" },
    { key: 234, value: "Vanuatu", label: "Vanuatu" },
    { key: 235, value: "Venezuela", label: "Venezuela" },
    { key: 236, value: "Viet Nam", label: "Viet Nam" },
    { key: 237, value: "Virgin Islands, British", label: "Virgin Islands, British" },
    { key: 238, value: "Virgin Islands, U.S.", label: "Virgin Islands, U.S." },
    { key: 239, value: "Wallis and Futuna", label: "Wallis and Futuna" },
    { key: 240, value: "Western Sahara", label: "Western Sahara" },
    { key: 241, value: "Yemen", label: "Yemen" },
    { key: 242, value: "Zambia", label: "Zambia" },
    { key: 243, value: "Zimbabwe", label: "Zimbabwe" },
  ];
  