import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";

const initialState = {
  ID: null,
  QHowDamageOccurred: "",
  QWitnesses: null,
  QWitnessesNames: "",
  QOperationInterrupted: null,
  PropertyType: 0,
};

export default class TypePropertyDamage extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <FormDropdown
          value={this.state.PropertyType}
          onChange={this.handleUserInput}
          name="PropertyType"
          title="Please define the property damage type"
          filter="Property Damage"
          readOnly={this.props.readOnly}
          stackLabel={true}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 4)}
          dropdownContentType="Type of Property Damage"
        ></FormDropdown>
        <FormFieldQuestion
          question="Please define how the damage occurred?"
          value={this.state.QHowDamageOccurred}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
          name="QHowDamageOccurred"
          readOnly={this.props.readOnly}
          filter="Property Damage"
          required={true}
          maxLength={500}
        ></FormFieldQuestion>
        <FormFieldRadioQuestion
          title="Were there any witnesses?"
          filter="Property Damage"
          name="QWitnesses"
          readOnly={this.props.readOnly}
          required={true}
          value={this.state.QWitnesses}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        {this.state.QWitnesses && (
          <FormFieldQuestion
            question="Can you please supply the witnesses names?"
            value={this.state.QWitnessesNames}
            onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
            readOnly={this.props.readOnly}
            name="QWitnessesNames"
            filter="Property Damage"
            maxLength={500}
          ></FormFieldQuestion>
        )}
        <FormFieldRadioQuestion
          title="Was the operation interrupted?"
          readOnly={this.props.readOnly}
          filter="Property Damage"
          name="QOperationInterrupted"
          required={true}
          value={this.state.QOperationInterrupted}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
      </>
    );
  }
}
