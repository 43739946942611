import React from "react";
import Select from "react-select";
import makeAnimated from "react-select/animated";

/* eslint-disable */
const customStylesMultiSelect = {
  placeholder: (provided) => ({
    ...provided,
    color: "#9e9e9e",
    fontSize: "13px",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#254F9E",
    fontSize: "13px",
    maxWidth: "150px",
    color: "white",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "10px",
  }),
};
const largeStylesMultiSelect = {
  placeholder: (provided) => ({
    ...provided,
    color: "#9e9e9e",
    borderColor: "red",
    fontSize: "15px",
  }),
  control: (provided) => ({
    ...provided,
    borderColor: "#ebebeb",
    borderRadius: "8px",
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: "#254F9E",
    fontSize: "15px",
    maxWidth: "150px",
    color: "white",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: "white",
  }),
  option: (provided) => ({
    ...provided,
    fontSize: "15px",
  }),
};

export default class SelectMultiple extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
  }
  render() {
    return (
      <Select
        isSearchable
        isMulti={!this.props.single}
        isLoading={this.props.isLoading}
        closeMenuOnSelect={this.props.single}
        components={makeAnimated()}
        isDisabled={this.props.disabled}
        isClearable={true}
        styles={
          this.props.largeStyles
            ? largeStylesMultiSelect
            : customStylesMultiSelect
        }
        placeholder={this.props.placeholder}
        onChange={this.props.onChange}
        options={this.props.options}
        value={this.props.value}
      ></Select>
    );
  }
}
