import {
  faCalendar,
  faCircleCheck,
  faHardHat,
  faHelmetSafety,
  faPersonFallingBurst,
  faRefresh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { toast } from "react-toastify";
import { Col, Row } from "reactstrap";
import styled from "styled-components";
import { IncidentStatus, IncidentType } from "../../../data/enums";
import { getUserInfo } from "../../../services/auth";
import { GetIncidentForm } from "../../../services/incidentReport";
import Center from "../../atoms/center";
import FilterContainer from "../../atoms/filterContainer";
import FilterLabel from "../../atoms/filterLabel";
import FormFieldCheckbox from "../../atoms/formFieldCheckbox";
import FormFieldType from "../../atoms/formFieldType";
import Link from "../../atoms/link";
import Loading from "../../atoms/loading";
import LocalDate from "../../atoms/localDate";
import Pagination from "../../atoms/pagination";
import SelectMultiple from "../../atoms/selectMultiple";
import TableRow from "../../atoms/tableRow";
import TableRowHeader from "../../atoms/tableRowHeader";
import TextBoxFilter from "../../molecules/textboxFilter";

/* eslint-disable */

const Container = styled.div`
  margin-left: 15px;
  margin-right: 15px;
`;
const CustomCol = styled(Col)`
  padding: 0 !important;
`;

const CustomText = styled.span`
  @media screen and (max-width: 768px) {
    font-size: 19px;
  }
  @media screen and (max-width: 500px) {
    font-size: 17px;
  }
`;

const StyledRefresh = styled(FontAwesomeIcon)`
  font-size: 30px;
  position: absolute;
  right: 15px;
  top: 15px;
  z-index: 9;
  cursor: pointer;
  &:hover {
    color: #0a54a5;
  }
`;

export default class IncidentReportHistory extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      loading: true,
      closed: false,
      activePage: 1,
      itemsPerPage: 15,
      FilterStatus: IncidentStatus.filter((x) => x.value !== "3"),
      FilterType: IncidentType,
      FilterSearch: "",
      total: 0,
    };
    this.refreshForm(this.state.itemsPerPage, 0);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.refreshFormParent = this.refreshFormParent.bind(this);
  }

  refreshFormParent() {
    this.refreshForm(this.state.itemsPerPage, 0);
  }

  refreshForm(limit, offset) {
    this.setState({
      history: [],
      loading: true,
    });
    var payload = {
      Limit: limit,
      Offset: offset,
      SN: "",
      IsEHS: this.props.isEHS ? true : false,
      Email: getUserInfo() === null ? "NoEmail" : getUserInfo().Email,
      IsManager: this.props.isManager ? true : false,
      FilterSearch: this.state.FilterSearch,
      FilterStatus: this.state.FilterStatus.map((item) => {
        return item.value;
      }),
      FilterType: this.state.FilterType.map((item) => {
        return item.value;
      }),
    };

    GetIncidentForm(payload).then((result) => {
      if (result === undefined) {
        return;
      }
      if (result.err) {
        toast.error(result.err.message);
      } else {
        this.setState({
          history: result.Data,
          loading: false,
          total: result.TotalCount,
        });
      }
    });
  }

  showClosed = (event) => {
    this.setState(
      {
        FilterStatus: event.target.checked
          ? IncidentStatus.filter((x) => x.value === "3")
          : IncidentStatus.filter((x) => x.value !== "3"),
        loading: true,
        closed: event.target.checked,
      },
      () => {
        this.refreshForm(this.state.itemsPerPage, 0);
      }
    );
  };

  refreshFormStatus = (selectedGroups) => {
    this.setState({ FilterStatus: selectedGroups, loading: true }, () => {
      this.refreshForm(this.state.itemsPerPage, 0);
    });
  };

  refreshFormType = (selectedGroups) => {
    this.setState({ FilterType: selectedGroups, loading: true }, () => {
      this.refreshForm(this.state.itemsPerPage, 0);
    });
  };
  refreshFormSearch = (search) => {
    this.setState({ FilterSearch: search, loading: true }, () => {
      this.refreshForm(this.state.itemsPerPage, 0);
    });
  };

  handlePageChange(pageNumber) {
    this.setState({ loading: true, activePage: Number(pageNumber) }, () => {
      this.refreshForm(
        this.state.itemsPerPage,
        Number(pageNumber) !== 1
          ? (Number(pageNumber) - 1) * this.state.itemsPerPage
          : 0
      );
    });
  }

  render() {
    const history = this.state.history.map((item, key) => (
      <TableRow key={key}>
        <Col xs="6" md="2">
          <Link
            onClick={() => {
              this.props.search(
                item.GUID,
                this.props.isManager,
                item.IncidentNumber
              );
            }}
          >
            <CustomText>
              <FontAwesomeIcon icon={faHelmetSafety}></FontAwesomeIcon>&nbsp;
              {item.IncidentNumber}
            </CustomText>
          </Link>
        </Col>
        <Col xs="2" className="d-none d-md-block">
          <FormFieldType>{item.TypeName}</FormFieldType>
        </Col>
        <Col xs="2" className="d-none d-md-block">
          <FormFieldType>
            {
              IncidentStatus.filter((x) => x.key === item.Status.toString())[0]
                ?.label
            }
          </FormFieldType>
        </Col>

        <Col>
          <LocalDate>{item.CreatedOn}</LocalDate>
          &nbsp;<b>By</b>&nbsp;
          {item.Name}
        </Col>
        <Col className="d-none d-md-block">
          <LocalDate>{item.ModifiedOn}</LocalDate>
        </Col>
        <Col xs="12" className="d-block d-md-none">
          <FormFieldType>{item.TypeName}</FormFieldType>
          <FormFieldType>
            {
              IncidentStatus.filter((x) => x.key === item.Status.toString())[0]
                ?.label
            }
          </FormFieldType>
        </Col>
      </TableRow>
    ));
    return (
      <Container>
        <Row>
          <FilterContainer>
            <StyledRefresh
              icon={faRefresh}
              onClick={() => {
                this.refreshForm(this.state.itemsPerPage, 0);
              }}
            ></StyledRefresh>
            <Row>
              <Col xs="12" md="6">
                <TextBoxFilter
                  title="EHS Number"
                  isUpper={false}
                  refresh={this.refreshFormSearch}
                ></TextBoxFilter>
              </Col>
              <Col xs="12" md="6">
                <FilterLabel>Show Completed Tickets</FilterLabel>
                <FormFieldCheckbox
                  style={{ marginTop: "10px" }}
                  noLabel={true}
                  onChange={this.showClosed}
                ></FormFieldCheckbox>
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FilterLabel>Status</FilterLabel>
                <SelectMultiple
                  placeholder={"Select Incident Status"}
                  onChange={this.refreshFormStatus}
                  options={IncidentStatus}
                  value={this.state.FilterStatus}
                />
              </Col>
            </Row>
            <Row>
              <Col xs="12">
                <FilterLabel>Type</FilterLabel>
              </Col>
              <Col xs="12">
                <SelectMultiple
                  placeholder={"Select Incident Type"}
                  onChange={this.refreshFormType}
                  options={IncidentType}
                  value={this.state.FilterType}
                />
              </Col>
            </Row>
          </FilterContainer>
        </Row>
        {!this.state.loading && (
          <Center>
            <b style={{ fontSize: "25px" }}>
              <FontAwesomeIcon icon={faHardHat}></FontAwesomeIcon>&nbsp;Total{" "}
              {this.state.closed ? "Closed" : "Open"}
              &nbsp;Tickets:&nbsp;{this.state.total}
            </b>
          </Center>
        )}
        <TableRowHeader bgColor={this.props.isEHS ? "gold" : "#003ba8"}>
          <Col xs="6" md="2">
            <FontAwesomeIcon icon={faHelmetSafety}></FontAwesomeIcon>&nbsp;EHS
            Number
          </Col>
          <Col xs="2" className="d-none d-md-block">
            <FontAwesomeIcon icon={faPersonFallingBurst}></FontAwesomeIcon>
            &nbsp;&nbsp;Type
          </Col>
          <Col xs="2" className="d-none d-md-block">
            <FontAwesomeIcon icon={faCircleCheck}></FontAwesomeIcon>
            &nbsp;&nbsp;Status
          </Col>
          <Col>
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            &nbsp;&nbsp;Created On
          </Col>
          <Col className="d-none d-md-block">
            <FontAwesomeIcon icon={faCalendar}></FontAwesomeIcon>
            &nbsp;&nbsp;Modified On
          </Col>
        </TableRowHeader>
        {this.state.loading && (
          <Row>
            <Loading size="lg"></Loading>
          </Row>
        )}
        <Row className="stripe">
          <Col>{history}</Col>
        </Row>
        {this.state.total > 0 && (
          <Row>
            <Pagination
              activePage={this.state.activePage ? this.state.activePage : 1}
              itemsCountPerPage={this.state.itemsPerPage}
              totalItemsCount={this.state.total}
              pageRangeDisplayed={10}
              onChange={this.handlePageChange}
            />
          </Row>
        )}
        {this.state.total == 0 && !this.state.loading && (
          <TableRow>
            <Col>No EHS Tickets have been found.</Col>
          </TableRow>
        )}
      </Container>
    );
  }
}
