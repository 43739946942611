import React from "react";
import Label from "./label";
import styled from "styled-components";
import Checkbox from "./checkbox";
import { Col, Row } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import Center from "./center";

const StyledContainer = styled.div`
  margin-bottom: 5px;
`;
const StyledLabel = styled(Label)`
  @media screen and (max-width: 993px) {
    float: left;
  }
`;

export default class FormFieldCheckbox extends React.Component {
  render() {
    return (
      <StyledContainer>
        <Row>
          {this.props.extra && (
            <Col xs="2" sm="1">
              <Center>
                <FontAwesomeIcon
                  style={{
                    fontSize: "30px",
                    color: "#ad0101",
                    marginTop: "3px",
                  }}
                  className="fa-beat"
                  icon={faExclamationTriangle}
                ></FontAwesomeIcon>
              </Center>
            </Col>
          )}
          {!this.props.noLabel && (
            <Col
              xs="10"
              sm="7"
              style={{
                textAlign: this.props.align ? this.props.align : "left",
              }}
            >
              <StyledLabel>
                {this.props.title}
                {this.props.required && this.props.title ? "*" : ""}
              </StyledLabel>
            </Col>
          )}
          <Col
            xs="12"
            sm="4"
            style={{ textAlign: this.props.extra ? "right" : "left" }}
          >
            &nbsp;
            <Checkbox
              disabled={this.props.readonly}
              {...this.props}
              title={this.props.checkboxTitle}
            ></Checkbox>
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
