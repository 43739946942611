import React from "react";
import { Col, Input, Row } from "reactstrap";
import styled from "styled-components";
import FormFieldType from "./formFieldType";
import Label from "./label";
import Required from "./required";

const StyledContainer = styled.div`
  margin-bottom: 15px;
`;
const StyledLabel = styled(Label)`
  @media screen and (max-width: 993px) {
    float: left;
  }
`;
const StyledRadioLabel = styled(Label)`
  font-size: 20px;
  position: relative;
  font-weight: normal;
  top: 7px;
  margin-right: 20px;
  cursor: pointer;
  @media only screen and (max-width: 900px) {
    top: 0px;
  }
`;

const StyledRadio = styled(Input)`
  height: 30px;
  width: 30px;
  margin-right: 5px;
  cursor: pointer;
`;
export default class FormFieldRadioQuestion extends React.Component {
  render() {
    return (
      <StyledContainer>
        <Row>
          {!this.props.noLabel && (
            <Col xs="12">
              <StyledLabel>
                {this.props.title}
                {this.props.required ? <Required></Required> : ""}
                {this.props.filter ? (
                  <FormFieldType>{this.props.filter}</FormFieldType>
                ) : (
                  ""
                )}
              </StyledLabel>
            </Col>
          )}
          <Col xs="12">
            <StyledRadio
              onChange={this.props.onChange}
              type="radio"
              id={"yes" + this.props.name}
              required={this.props.required}
              disabled={this.props.readOnly}
              checked={this.props.value == 1}
              name={this.props.name}
              value="yes"
            />
            <StyledRadioLabel for={"yes" + this.props.name}>
              Yes
            </StyledRadioLabel>

            <StyledRadio
              onChange={this.props.onChange}
              type="radio"
              name={this.props.name}
              required={this.props.required}
              checked={this.props.value === false}
              disabled={this.props.readOnly}
              id={"no" + this.props.name}
              value="no"
            />
            <StyledRadioLabel for={"no" + this.props.name}>No</StyledRadioLabel>
            {this.props.na && (
              <>
                <StyledRadio
                  onChange={this.props.onChange}
                  type="radio"
                  name={this.props.name}
                  required={this.props.required}
                  checked={this.props.value === null}
                  disabled={this.props.readOnly}
                  id={"na" + this.props.name}
                  value="na"
                />
                <StyledRadioLabel for={"na" + this.props.name}>
                  N/A
                </StyledRadioLabel>
              </>
            )}
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
