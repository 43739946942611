import React from "react";
import { GetDictionaryItem } from "../../services/incidentReport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleQuestion } from "@fortawesome/free-solid-svg-icons";

export default class Dictionary extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      typeDef: "",
    };
  }
  componentDidMount() {
    if (this.props.item !== 0) {
      this.loadTypeDef(this.props.item);
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.item !== this.props.item) {
      this.loadTypeDef(this.props.item);
    }
  }

  loadTypeDef = (item) => {
    GetDictionaryItem(item).then((res) => {
      this.setState({
        typeDef: (
          <>
            <b>{item}:&nbsp;</b>
            <i dangerouslySetInnerHTML={{ __html: res }}></i>
          </>
        ),
      });
    });
  };

  render() {
    return (
      <>
        {this.state.typeDef &&
          this.props.item !== 0 &&
          this.props.item !== "0" && (
            <div style={{ fontSize: "15px" }}>
              <FontAwesomeIcon
                style={{ color: "#2a98e9", fontSize: "23px" }}
                icon={faCircleQuestion}
              ></FontAwesomeIcon>
              &nbsp;{this.state.typeDef}
            </div>
          )}
      </>
    );
  }
}
