import axios from "axios";

var apiUrl = `${process.env.GATSBY_API_URL}/api/`;

export const LoginEHS = (token) => {
  var submitResults = axios
    .get(`${apiUrl}ad/LoginEHS?token=${token}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { err: e };
    });

  return submitResults;
};

export const GetUserInformation = (user) => {
  var submitResults = axios
    .get(`${apiUrl}ad/GetUserInformation?userEmail=${user}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { err: e };
    });

  return submitResults;
};

export const SearchUser = (user) => {
  var submitResults = axios
    .get(`${apiUrl}ad/SearchUser?search=${user}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response.data;
    })
    .catch((e) => {
      return { err: e };
    });

  return submitResults;
};

