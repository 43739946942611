import React from "react";
import Label from "./label";
import styled from "styled-components";
import { Col, Row } from "reactstrap";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Required from "./required";
import FormFieldType from "./formFieldType";

const StyledContainer = styled.div`
  margin-bottom: 5px;
`;
const StyledLabel = styled(Label)`
  @media screen and (max-width: 993px) {
    float: left;
  }
  @media (min-width: 576px) {
    height: 21px;
    margin-top: 5px;
  }
`;

export default class FormFieldDatePicker extends React.Component {
  render() {
    return (
      <StyledContainer>
        <Row>
          {!this.props.noLabel && (
            <Col
              xs="12"
              style={{
                textAlign: this.props.align ? this.props.align : "left",
              }}
            >
              <StyledLabel>
                {this.props.title}
                {this.props.required ? <Required></Required> : ""}
                {this.props.filter ? (
                  <FormFieldType>{this.props.filter}</FormFieldType>
                ) : (
                  ""
                )}
              </StyledLabel>
            </Col>
          )}
          <Col xs="12">
            <DatePicker
              isClearable
              {...this.props}
              autoComplete="off"
              placeholderText={"Select Date"}
            ></DatePicker>
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
