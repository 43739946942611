import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import styled from "styled-components";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";

const PageMessage = styled.div`
  text-align: center;
  padding: 10px 50px;
  color: #0b509c;
  border-top: 1px dashed #c7c7c7;
  border-bottom: 1px dashed #c7c7c7;
  margin-bottom: 10px;
`;

const initialState = {
  ID: null,
  QDefineHazard: "",
  EnvironmentalType: 0,
};

export default class TypeEnvironmental extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <PageMessage>
          <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>&nbsp; Note: If
          the incident triggered an emergency situation such as fire, explosion,
          reportable chemical spill, etc., the employee shall proceed as per
          local Emergency Response Plan.
        </PageMessage>
        <FormDropdown
          value={this.state.EnvironmentalType}
          onChange={this.handleUserInput}
          name="EnvironmentalType"
          title="Please Choose an Environment Incident type."
          readOnly={this.props.readOnly}
          filter="Environmental"
          stackLabel={true}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 3)}
          dropdownContentType="Type"
        ></FormDropdown>
        <FormFieldQuestion
          question="Please define the hazard (i.e., fire alarm, hazardous material, combustibles, compressed gases, etc.)"
          value={this.state.QDefineHazard}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
          readOnly={this.props.readOnly}
          name="QDefineHazard"
          filter="Environmental"
          required={true}
          maxLength={500}
        ></FormFieldQuestion>
      </>
    );
  }
}
