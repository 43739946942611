import React from "react";
import styled from "styled-components";

const Container = styled.div`
  position: fixed;
  right: -5px;
  bottom: 0;
  padding: 10px;
  padding-right: 0;
  border-radius: 18px 0 0 0px;
  z-index: 1000;
  background-color: white;
  border-top: 2px solid #ababab;
  border-left: 2px solid #ababab;
  box-shadow: rgb(48 46 46) 0px 4px 6px -1px, rgb(0 0 0 / 6%) 0px 2px 4px -1px;
  @media only screen and (max-width: 900px) {
    font-size: 17px;
    display: none;
  }
`;
const FloatingButton = ({ ...props }) => {
  return <Container>{props.children}</Container>;
};

export default FloatingButton;
