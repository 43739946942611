import React from "react";
import styled from "styled-components";

import Label from "../atoms/label";

const CheckboxContainer = styled.div`
  display: inline-block;
  vertical-align: middle;
  cursor: pointer;
`;

const StyledLabel = styled(Label)`
  margin-left: 5px;
  position: relative;
  margin-right: 15px;
  padding-top: 3px;
  cursor: pointer;
`;

const StyledLabelNoHeight = styled(Label)`
  margin-left: 5px;
  position: relative;
  margin-right: 15px;
  padding-top: 3px;
  height: 0px !important;
  cursor: pointer;
`;

const ToggleAllLabel = styled(Label)`
  margin-left: 5px;
  position: relative;
  margin-right: 15px;
  padding-top: 3px;
  font-size: 18px;
  color: #004d8d;
  cursor: pointer;
`;

const StyledCheckbox = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: ${(props) => (props.large ? "50px" : "24px")};
  height: ${(props) => (props.large ? "50px" : "24px")};
  line-height: ${(props) => (props.large ? "50px" : "24px")};
  padding: 0 1px;
  font-size: ${(props) => (props.large ? "40px" : "16px !important")};
  background: ${(props) => (props.checked ? "#e8e8e8" : "#fff")};
  border-radius: 3px;
  border: 1px solid #444;
  transition: all 150ms;
  ${"div"} {
    visibility: ${(props) => (props.checked ? "visible" : "hidden")};
    text-align: center;
    font-weight: bold;
  }
`;

const CheckboxItem = styled.input.attrs({ type: "checkbox" })`
  display: inline-block;
  width: ${(props) => (props.large ? "50px" : "24px")};
  height: ${(props) => (props.large ? "50px" : "24px")};
  line-height: ${(props) => (props.large ? "50px" : "24px")};
  padding: 0 1px;
  font-size: ${(props) => (props.large ? "40px" : "16px !important")};
  background: ${(props) => (props.checked ? "#e8e8e8" : "#fff")};
  cursor: pointer;
`;

const Checkbox = ({ className, ...props }) => (
  <label style={{ marginBottom: props.marginBottom !== null ? props.marginBottom : "0" }}>
    <CheckboxContainer className={className}>
      {props.disabled && <StyledCheckbox style={{ backgroundColor: "#ebebe4" }} {...props} id={props.name}></StyledCheckbox>}
      {!props.disabled && (
        <>
          <CheckboxItem {...props} id={props.name} />
        </>
      )}
    </CheckboxContainer>
    {props.isToggleAll ? (
      <ToggleAllLabel htmlFor={props.name}>{props.title}</ToggleAllLabel>
    ) : props.noHeight ? (
      <StyledLabelNoHeight htmlFor={props.name}>{props.title}</StyledLabelNoHeight>
    ) : (
      <StyledLabel htmlFor={props.name}>{props.title}</StyledLabel>
    )}
  </label>
);

export default Checkbox;
