import React from "react";
import { Row, Col } from "reactstrap";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faBan,
  faChevronRight,
  faEdit,
  faSave,
  faStickyNote,
  faTrash,
} from "@fortawesome/free-solid-svg-icons";
import { DeleteBlogNote, SaveBlogNote } from "../../services/blog";
import FormFieldTextArea from "../atoms/formFieldTextArea";
import Button from "../atoms/button";
import { toast } from "react-toastify";
import LocalDate from "../atoms/localDate";
import { getUserInfo } from "../../services/auth";

const StyledContainer = styled.div`
  font-size: 15px;
  margin-bottom: 10px;
  background-color: #eeeeee;
  color: #444;
  border: 1px solid #8f8f8f;
  padding: 5px 15px;

  &:hover {
  }
`;

const HeaderRow = styled(Row)`
  background-color: #616161;
  color: white;
  font-size: 16px;
  padding: 5px 0;
  margin: 0 !important;
  border-radius: 8px 8px 0 0;
  font-size: 16px;
  color: white;
  font-weight: bold;
  border: 1px solid #b7b7b7;
  border-bottom: 1px solid #707070;
  background: repeating-linear-gradient(
    45deg,
    rgb(63, 63, 63),
    rgb(63, 63, 63) 20px,
    rgb(77, 77, 77) 20px,
    rgb(77, 77, 77) 40px
  );
  @media only screen and (max-width: 900px) {
    font-size: 14px;
  }
`;

const DateLabel = styled.span`
  float: right;
  font-size: 12px;
  font-style: italic;
  line-height: 25px;
`;
const Icon = styled(FontAwesomeIcon)`
  margin-right: 5px;
  margin-left: 5px;
  cursor: pointer;
`;

export default class BlogItem extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      edit: false,
      newNote: props.item.Notes,
      showAlert: false,
      user: "",
    };
    this.edit = this.edit.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }
  componentDidMount() {
    this.setState({ user: getUserInfo()?.Email.toLowerCase() });
  }

  removeOpen() {
    this.setState({ showAlert: true });
  }
  remove = (id) => {
    this.setState({ edit: false });
    this.closeAlert();
    DeleteBlogNote(id).then((res) => {
      if (res.data) {
        toast.success("Log Successfully Deleted");
        this.props.callback();
      } else {
        toast.error("Error removing Log");
      }
    });
  };

  edit = () => {
    this.setState({ edit: true, newNote: this.props.item.Notes });
  };
  cancel = () => {
    this.setState({ edit: false, newNote: this.props.item.Notes });
  };
  save = () => {
    this.props.item.Notes = this.state.newNote;
    this.props.item.ModifiedBy = getUserInfo().Email;
    SaveBlogNote(this.props.item).then((res) => {
      if (res.data) {
        toast.success("Note Added Successfully");
        this.setState({ edit: false });
      } else {
        toast.error("Log Failed");
      }
    });
  };

  handleUserInput = (event) => {
    if (!this.state.readOnlyAccess) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({ [name]: value });
    }
  };
  closeAlert = () => {
    this.setState({ showAlert: false, submitting: false });
  };

  render() {
    return (
      <>
        <HeaderRow>
          <Col>
            {this.props.type === 2 && <Icon icon={faStickyNote}></Icon>}
            <i>{this.props.item.CreatedBy.toLowerCase()}</i>
            <Icon icon={faChevronRight}></Icon>
            <DateLabel>
              <b>Created On:</b>{" "}
              <LocalDate>{this.props.item.CreatedOn}</LocalDate>
            </DateLabel>
          </Col>
        </HeaderRow>
        <Row>
          <Col>
            <StyledContainer>
              {this.state.edit && (
                <>
                  <FormFieldTextArea
                    noLabel={true}
                    title="Edit"
                    value={this.state.newNote}
                    onChange={this.handleUserInput}
                    name="newNote"
                    maxLength="1000"
                  ></FormFieldTextArea>
                  <Row>
                    <Col>
                      {!this.props.readOnly && (
                        <Button
                          style={{
                            fontSize: "14px",
                            height: "30px",
                            padding: "2px 13px 2px 12px",
                          }}
                          onClick={() => this.cancel()}
                        >
                          <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                          &nbsp;&nbsp;Cancel
                        </Button>
                      )}
                    </Col>
                    <Col>
                      {!this.props.readOnly && (
                        <Button
                          style={{
                            float: "right",
                            fontSize: "14px",
                            height: "30px",
                            padding: "2px 13px 2px 12px",
                          }}
                          onClick={() => this.save()}
                        >
                          <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
                          &nbsp;&nbsp;Update
                        </Button>
                      )}
                    </Col>
                  </Row>
                </>
              )}
              {!this.state.edit && (
                <>
                  {this.props.item.Notes}
                  {!this.props.readOnly &&
                    this.props.item.CreatedBy.toLowerCase() ===
                      this.state.user && (
                      <>
                        <Button
                          style={{
                            padding: "2px 13px 2px 6px",
                            marginLeft: "10px",
                            height: "30px",
                            fontSize: "14px",
                          }}
                          onClick={() => this.edit()}
                        >
                          <Icon icon={faEdit}></Icon> Edit
                        </Button>
                        <Button
                          style={{
                            padding: "2px 13px 2px 6px",
                            marginLeft: "10px",
                            height: "30px",
                            fontSize: "14px",
                          }}
                          onClick={() => this.remove(this.props.item.ID)}
                        >
                          <Icon icon={faTrash}></Icon> Remove
                        </Button>
                      </>
                    )}
                </>
              )}
            </StyledContainer>
          </Col>
        </Row>
      </>
    );
  }
}
