import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";

const initialState = {
  ID: null,
  PartOfBody: 0,
  QFirstAidProvided: null,
  QRefuseFirstAid: null,
  QClinicEmergency: null,
  QMedicalProvider: "",
};

export default class TypeFirstAid extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <FormDropdown
          value={this.state.PartOfBody}
          onChange={this.handleUserInput}
          name="PartOfBody"
          title="What part of the body was affected?"
          stackLabel={true}
          filter="First Aid"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1001)}
          dropdownContentType="Part of Body"
        ></FormDropdown>
        <FormFieldRadioQuestion
          title="Was First Aid provided?"
          filter="First Aid"
          name="QFirstAidProvided"
          readOnly={this.props.readOnly}
          required={true}
          value={this.state.QFirstAidProvided}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        {this.state.QFirstAidProvided && (
          <FormFieldQuestion
            question="Where was First Aid provided and by whom?"
            value={this.state.QMedicalProvider}
            onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
            name="QMedicalProvider"
            filter="First Aid"
            readOnly={this.props.readOnly}
            required={true}
            maxLength={500}
          ></FormFieldQuestion>
        )}
        <FormFieldRadioQuestion
          title="Did the employee refuse First Aid?"
          filter="First Aid"
          name="QRefuseFirstAid"
          readOnly={this.props.readOnly}
          required={true}
          value={this.state.QRefuseFirstAid}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
      </>
    );
  }
}
