import React from "react";
import styled from "styled-components";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ifProp } from "styled-tools";

const FilterTitle = styled.span`
  font-size: 16px;
  font-weight: bold;
  color: ${ifProp("active", "#64259e", "#333333")};
`;

const FilterLabel = ({ ...props }) => {
  return (
    <FilterTitle {...props}>
      <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>&nbsp;{props.children}
    </FilterTitle>
  );
};

export default FilterLabel;
