import React from "react";
import Label from "./label";
import styled from "styled-components";
import Input from "./input";
import { Col, Row } from "reactstrap";
import Required from "./required";
import FormFieldType from "./formFieldType";

const StyledContainer = styled.div`
  margin-bottom: 5px;
`;
const StyledLabel = styled(Label)`
  @media screen and (max-width: 993px) {
    float: left;
  }
  @media (min-width: 576px) {
    margin-top: 5px;
  }
`;

const CounterLabel = styled(Label)`
  position: relative;
  right: 5px;
  top: -4px;
  font-size: 10px;
  color: #7d7d7d;
  float: right;
  height: 15px !important;
`;
const StyledTextArea = styled(Input)`
  padding-top: 10px;
`;

export default class FormFieldQuestion extends React.Component {
  render() {
    return (
      <StyledContainer>
        <Row>
          <Col>
            <StyledLabel>
              {this.props.question}
              {this.props.required ? <Required></Required> : ""}
              {this.props.filter ? (
                <FormFieldType>{this.props.filter}</FormFieldType>
              ) : (
                ""
              )}
            </StyledLabel>
          </Col>
        </Row>
        <Row>
          <Col xs="12" sm="12" md={12}>
            <StyledTextArea
              type="textarea"
              {...this.props}
              disabled={this.props.readOnly}
              style={{
                height: this.props.minHeight,
                minHeight: this.props.minHeight,
              }}
              placeholder={"Enter Details"}
            ></StyledTextArea>
            {this.props.value && (
              <CounterLabel>
                {this.props.value.length === this.props.maxLength
                  ? "0"
                  : this.props.maxLength - this.props.value.length}
              </CounterLabel>
            )}
            {!this.props.value && (
              <CounterLabel>{this.props.maxLength}</CounterLabel>
            )}
          </Col>
        </Row>
      </StyledContainer>
    );
  }
}
