import React from "react";
import { ChangeTypes } from "../../../data/enums";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormFieldRadioQuestion from "../../atoms/formFieldRadioQuestion";

const initialState = {
  ID: null,
  PartOfBody: 0,
  QMedicalTreatment: null,
  QClinicEmergency: null,
  QRefuseTreatment: null,
  QWitnesses: null,
  QWitnessesInfo: "",
  InjuryType: 0,
  QMechanismType: 0
};

export default class TypeInjury extends React.Component {
  constructor(props) {
    super(props);
    this.state = initialState;
  }
  componentDidMount() {
    this.setState(this.props.state, () => {
      this.setState(
        {
          IncidentGUID: this.props.guid,
          IncidentNumber: this.props.number,
        },
        () => {
          this.props.update(this.state);
        }
      );
    });
  }

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;
    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" }, () => {
          this.props.update(this.state);
        });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value }, () => {
          this.props.update(this.state);
        });
        break;
    }
  };

  render() {
    return (
      <>
        <FormDropdown
          value={this.state.InjuryType}
          onChange={this.handleUserInput}
          name="InjuryType"
          title="Injury Type"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1)}
          dropdownContentType="Type of Injury"
        ></FormDropdown>
        <FormDropdown
          value={this.state.PartOfBody}
          onChange={this.handleUserInput}
          name="PartOfBody"
          title="What part of the body was affected?"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1001)}
          dropdownContentType="Part of Body"
        ></FormDropdown>
        <FormDropdown
          value={this.state.QMechanismType}
          onChange={this.handleUserInput}
          name="QMechanismType"
          title="What was the Injury/illness mechanism?"
          stackLabel={true}
          filter="Injury/Illness"
          readOnly={this.props.readOnly}
          required={true}
          options={this.props.dropdowns.filter((x) => x.typeID === 1002)}
          dropdownContentType="Mechanism"
        ></FormDropdown>
        <FormFieldRadioQuestion
          title="Was medical treatment provided?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QMedicalTreatment"
          required={true}
          value={this.state.QMedicalTreatment}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />

        <FormFieldRadioQuestion
          title="Was the injured worker taken to a Clinic/Emergency Room?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QClinicEmergency"
          required={true}
          value={this.state.QClinicEmergency}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        <FormFieldRadioQuestion
          title="Did the Employee refuse medical treatment or transport to the Emergency Room?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QRefuseTreatment"
          required={true}
          value={this.state.QRefuseTreatment}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        <FormFieldRadioQuestion
          title="Were there any witnesses?"
          readOnly={this.props.readOnly}
          filter="Injury/Illness"
          name="QWitnesses"
          required={true}
          value={this.state.QWitnesses}
          onChange={(event) => this.handleUserInput(event, ChangeTypes.CHECK)}
        />
        {this.state.QWitnesses && (
          <FormFieldQuestion
            question="If yes, please provide witness contact information"
            readOnly={this.props.readOnly}
            value={this.state.QWitnessesInfo}
            onChange={(event) => this.handleUserInput(event, ChangeTypes.NOTES)}
            name="QWitnessesInfo"
            filter="Injury/Illness"
            required={true}
            maxLength={500}
          ></FormFieldQuestion>
        )}
      </>
    );
  }
}
