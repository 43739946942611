import React from "react";
import { toast } from "react-toastify";
import { Button, Col, Row } from "reactstrap";
import { getUserInfo } from "../../../services/auth";
import Hr from "../../atoms/hr";

import {
  GetRootCauses,
  RemoveRootCause,
  SubmitRootCauseForm,
} from "../../../services/incidentReport";

import {
  faBan,
  faCheckCircle,
  faCircleCheck,
  faInfoCircle,
  faKeyboard,
  faListCheck,
  faPencil,
  faPlusCircle,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { ChangeTypes, RootCause } from "../../../data/enums";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";
import Center from "../../atoms/center";
import FormDropdown from "../../atoms/formDropdown";
import FormFieldQuestion from "../../atoms/formFieldQuestion";
import FormHeader from "../../atoms/formHeader";
import LargeButton from "../../atoms/largeButton";
import PageMessage from "../../atoms/pageMessage";
import TableRow from "../../atoms/tableRow";
import TableRowHeader from "../../atoms/tableRowHeader";
import Dictionary from "../../molecules/dictionaryLookup";

/* eslint-disable */

const initialState = {
  ID: null,
  IncidentGUID: "",
  Type: 0,
  Details: "",
  rootCauses: [],
  CreatedBy: "",
  submitting: false,
  formValid: true,
};

const EditButton = styled(Button)`
  font-size: 12px;
  padding: 5px 9px;
  float: right;
`;

export default class IRRootCauses extends React.Component {
  constructor(props) {
    super(props);

    this.child = React.createRef();
    this.state = initialState;

    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleUserInput = this.handleUserInput.bind(this);
    this.validateForm = this.validateForm.bind(this);
  }

  componentDidMount = () => {
    this.setState(
      {
        IncidentGUID: this.props.guid,
        CreatedBy: getUserInfo().Email,
      },
      () => {
        if (this.state.IncidentGUID !== "") {
          this.serialNumberSearch();
        }
      }
    );
  };

  clear = () => {
    this.setState({ ...initialState, IncidentGUID: this.props.guid }, () => {
      this.serialNumberSearch();
    });
    if (
      typeof window !== "undefined" &&
      document?.getElementsByTagName("form")[0]
    ) {
      document?.getElementsByTagName("form")[0].removeAttribute("class");
    }
  };

  submitForm() {
    let newState = JSON.parse(JSON.stringify(this.state));
    SubmitRootCauseForm({ formData: newState })
      .then((res) => {
        if (res.data.HasErrors) {
          res.data.ErrorList.forEach((item) => {
            toast.error(item);
          });
          this.setState({
            submitting: false,
          });
        } else {
          toast.success("Root Cause has been added");
          this.clear();
        }
      })
      .catch((error) => {
        toast.error("There was an error submitting the form.");
        this.setState({ submitting: false });
      });
  }

  serialNumberSearch = (search = "") => {
    GetRootCauses(search !== "" ? search : this.state.IncidentGUID)
      .then((res) => {
        if (typeof res === "string") {
          return;
        }
        if (res.HasErrors) {
          res.ErrorList.forEach((item) => {
            toast.error(item);
          });
        } else {
          if (res) {
            this.props.updateRC(res);
            this.setState({
              rootCauses: res,
            });
          }
        }
      })
      .catch((err) => {
        toast.error("Error");
      });
  };

  validateForm = () => {
    this.setState({ submitting: true }, () => {
      let isValid = true;
      if (this.state.Type === 0) {
        toast.error("Root Cause Type is Required.");
        isValid = false;
      }
      if (this.state.Details === "") {
        toast.error("Root Cause Details are Required.");
        isValid = false;
      }

      if (isValid) {
        this.submitForm(false);
      } else {
        this.setState({ submitting: false });
      }
    });
  };

  handleSubmit = (event) => {
    event.preventDefault();
    this.setState({ submitting: true }, () => {
      this.validateForm();
    });
  };

  editItem = (id) => {
    var caAll = this.state.rootCauses.filter((x) => x.ID === id);
    this.setState({
      Type: caAll[0].Type,
      ID: caAll[0].ID,
      Details: caAll[0].Details,
      CreatedOn: caAll[0].CreatedOn,
      CreatedBy: caAll[0].CreatedBy,
      ModifiedOn: caAll[0].ModifiedOn,
      ModifiedBy: getUserInfo().Email,
      Type: caAll[0].Type,
    });
  };

  deleteItem = (id) => {
    RemoveRootCause(id).then((res) => {
      if (res) {
        toast.success("Root Cause has been removed");
        this.clear();
      }
    });
  };

  handleUserInputArea = (event) => {
    const name = event.target.name;
    const value = event.target.value;
    this.setState({ [name]: value });
  };

  buttonAdjust = (value, field, isIncrease) => {
    if (isNaN(parseInt(value))) {
      value = 0;
    } else {
      value = parseInt(value);
    }
    let newVal = isIncrease ? value + 1 : value - 1;
    if (newVal >= 0 && newVal <= 1000) {
      this.setState({ [field]: newVal });
    }
  };

  handleUserInput = (event, key = ChangeTypes.NONE) => {
    const name = event.target.name;
    const value = event.target.value;

    switch (key) {
      case ChangeTypes.UPPER:
        this.setState({ [name]: value.toUpperCase().trim() });
        break;
      case ChangeTypes.NONE:
        this.setState({ [name]: value.trim() });
        break;
      case ChangeTypes.CHECK:
        this.setState({ [name]: value === "yes" });
        break;
      case ChangeTypes.NOTES:
      default:
        this.setState({ [name]: value });
        break;
    }
  };
  updateState = (name, value) => {
    this.setState({
      [name]: value,
    });
  };

  handleCheckChange = (event) => {
    this.setState({ [event.target.name]: event.target.checked });
  };

  render() {
    const actions = this.state.rootCauses?.map((item, key) => (
      <TableRow
        style={{
          backgroundColor: "#b8ffb8",
          border:
            item.ID.toString() === this.state.ID?.toString()
              ? "3px solid #0a54a5"
              : "",
        }}
        key={key}
      >
        <Col md="2" xs="12">
          <FontAwesomeIcon
            style={{
              fontSize: "18px",
              color: "green",
            }}
            icon={faCheckCircle}
          ></FontAwesomeIcon>
          &nbsp;{item.Type}
        </Col>
        <Col>
          {item.Details || "N/A"}
          {!this.props.disable && (
            <EditButton onClick={() => this.editItem(item.ID)}>
              <FontAwesomeIcon icon={faPencil}></FontAwesomeIcon>
            </EditButton>
          )}
        </Col>
      </TableRow>
    ));
    return (
      <>
        <PageMessage>
          <FontAwesomeIcon icon={faInfoCircle}></FontAwesomeIcon>
          &nbsp;At least one Root Cause is required
        </PageMessage>
        <Row style={{ padding: "0 10px" }}>
          <Col
            style={{
              backgroundColor: "#f1f1f1",
              border: "1px solid #cecece",
              borderRadius: "8px",
            }}
          >
            {!this.state.loading && (
              <>
                <TableRowHeader>
                  <Col md="2" className="d-none d-md-block">
                    <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>&nbsp;
                    Type
                  </Col>

                  <Col>
                    <FontAwesomeIcon icon={faKeyboard}></FontAwesomeIcon>
                    &nbsp; Details
                  </Col>
                </TableRowHeader>
                <Row>
                  <Col>{actions}</Col>
                </Row>
                {this.state.rootCauses?.length === 0 && (
                  <TableRow>
                    <Col>No Root Causes have been added</Col>
                  </TableRow>
                )}
                {!this.props.disable && (
                  <>
                    <Hr />
                    <FormHeader>
                      <FontAwesomeIcon icon={faListCheck}></FontAwesomeIcon>
                      &nbsp; <>{this.state.ID ? "Edit" : "Add"}</> New Root
                      Cause
                    </FormHeader>
                    <Center></Center>
                    <FormDropdown
                      value={this.state.Type}
                      onChange={this.handleUserInput}
                      stackLabel={true}
                      name="Type"
                      title="Root Cause Type"
                      readOnly={this.state.paired}
                      options={RootCause}
                      dropdownContentType="Root Cause Type"
                    ></FormDropdown>
                    <Dictionary item={this.state.Type}></Dictionary>
                    <FormFieldQuestion
                      readOnly={this.state.paired}
                      question="What was the root cause? (Consider asking yourself 5 'whys' to determine the most accurate root cause)"
                      value={this.state.Details}
                      onChange={this.handleUserInputArea}
                      name="Details"
                      minHeight="170px"
                      maxLength="1000"
                    ></FormFieldQuestion>
                    <Hr />
                    <Row>
                      {this.state.ID && (
                        <Col>
                          <Center>
                            <LargeButton
                              style={{ width: "100%" }}
                              large={true}
                              isRemove={true}
                              onClick={() => this.deleteItem(this.state.ID)}
                              icon={faCircleCheck}
                            >
                              <FontAwesomeIcon
                                icon={faTrashCan}
                              ></FontAwesomeIcon>
                              &nbsp;Remove
                            </LargeButton>
                          </Center>
                        </Col>
                      )}
                      {this.state.ID && (
                        <Col>
                          <Center>
                            <LargeButton
                              large={true}
                              style={{ width: "100%" }}
                              altColor={true}
                              onClick={() => this.clear()}
                              icon={faCircleCheck}
                            >
                              <FontAwesomeIcon icon={faBan}></FontAwesomeIcon>
                              &nbsp;Cancel
                            </LargeButton>
                          </Center>
                        </Col>
                      )}
                      <Col>
                        <Center>
                          <LargeButton
                            large={true}
                            altColor={!this.state.ID}
                            style={{ width: this.state.ID ? "100%" : "auto" }}
                            onClick={this.handleSubmit}
                            icon={faCircleCheck}
                          >
                            <FontAwesomeIcon
                              icon={this.state.ID ? faPencil : faPlusCircle}
                            ></FontAwesomeIcon>
                            &nbsp;
                            {this.state.ID ? "Update" : "Save Root Cause"}
                          </LargeButton>
                        </Center>
                      </Col>
                    </Row>
                  </>
                )}
              </>
            )}
            {this.state.loading && (
              <Center>
                <Loading abs={true} size="lg"></Loading>
              </Center>
            )}
          </Col>
        </Row>
        <Hr />
      </>
    );
  }
}
