export const States = [
  { key: 1, value: "Alabama", label: "Alabama" },
  { key: 2, value: "Alaska", label: "Alaska" },
  { key: 3, value: "Arizona", label: "Arizona" },
  { key: 4, value: "Arkansas", label: "Arkansas" },
  { key: 5, value: "California", label: "California" },
  { key: 6, value: "Colorado", label: "Colorado" },
  { key: 7, value: "Connecticut", label: "Connecticut" },
  { key: 8, value: "Delaware", label: "Delaware" },
  { key: 9, value: "Florida", label: "Florida" },
  { key: 10, value: "Georgia", label: "Georgia" },
  { key: 11, value: "Hawaii", label: "Hawaii" },
  { key: 12, value: "Idaho", label: "Idaho" },
  { key: 13, value: "Illinois", label: "Illinois" },
  { key: 14, value: "Indiana", label: "Indiana" },
  { key: 15, value: "Iowa", label: "Iowa" },
  { key: 16, value: "Kansas", label: "Kansas" },
  { key: 17, value: "Kentucky", label: "Kentucky" },
  { key: 18, value: "Louisiana", label: "Louisiana" },
  { key: 19, value: "Maine", label: "Maine" },
  { key: 20, value: "Maryland", label: "Maryland" },
  { key: 21, value: "Massachusetts", label: "Massachusetts" },
  { key: 22, value: "Michigan", label: "Michigan" },
  { key: 23, value: "Minnesota", label: "Minnesota" },
  { key: 24, value: "Mississippi", label: "Mississippi" },
  { key: 25, value: "Missouri", label: "Missouri" },
  { key: 26, value: "Montana", label: "Montana" },
  { key: 27, value: "Nebraska", label: "Nebraska" },
  { key: 28, value: "Nevada", label: "Nevada" },
  { key: 29, value: "New Hampshire", label: "New Hampshire" },
  { key: 30, value: "New Jersey", label: "New Jersey" },
  { key: 31, value: "New Mexico", label: "New Mexico" },
  { key: 32, value: "New York", label: "New York" },
  { key: 33, value: "North Carolina", label: "North Carolina" },
  { key: 34, value: "North Dakota", label: "North Dakota" },
  { key: 35, value: "Ohio", label: "Ohio" },
  { key: 36, value: "Oklahoma", label: "Oklahoma" },
  { key: 37, value: "Oregon", label: "Oregon" },
  { key: 38, value: "Pennsylvania", label: "Pennsylvania" },
  { key: 39, value: "Rhode Island", label: "Rhode Island" },
  { key: 40, value: "South Carolina", label: "South Carolina" },
  { key: 41, value: "South Dakota", label: "South Dakota" },
  { key: 42, value: "Tennessee", label: "Tennessee" },
  { key: 43, value: "Texas", label: "Texas" },
  { key: 44, value: "Utah", label: "Utah" },
  { key: 45, value: "Vermont", label: "Vermont" },
  { key: 46, value: "Virginia", label: "Virginia" },
  { key: 47, value: "Washington", label: "Washington" },
  { key: 48, value: "West Virginia", label: "West Virginia" },
  { key: 49, value: "Wisconsin", label: "Wisconsin" },
  { key: 50, value: "Wyoming", label: "Wyoming" },
];
