import React from "react";
import moment from "moment";

const LocalDate = ({ ...props }) => {
  if (moment(props.children).isValid()) {
    if(props.format)
    {
      return <>{moment.utc(props.children).local().format(props.format)}</>;
    }else{
      return <>{moment.utc(props.children).local().format("lll")}</>;
    }

   
  } else {
    return <>N/A</>;
  }
};

export default LocalDate;
