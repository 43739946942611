import React from "react";
import styled from "styled-components";
import Button from "../atoms/button";
import { faPlus, faMinus } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AdjustButtonContainer = styled.div`
  position: absolute;
  right: 9px;
  top: -3px;
  @media (max-width: 1500px) {
    right: 7px;
  }
`;
const AdjustButtonItem = styled(Button)`
  padding: 0px;
  height: 22px;
  display: block;
  margin-bottom: 3px;
  line-height: 10px;
  cursor: pointer;
  font-size: 16px;
  width: 22px;
  font-weight: bold;
`;
const AdjustButton = ({ ...props }) => {
  return (
    <AdjustButtonContainer>
      {!props.disabled && (
        <>
          <AdjustButtonItem onClick={props.onUpClick}>
            <FontAwesomeIcon icon={faPlus}></FontAwesomeIcon>
          </AdjustButtonItem>
          <AdjustButtonItem onClick={props.onDownClick}>
            <FontAwesomeIcon icon={faMinus}></FontAwesomeIcon>
          </AdjustButtonItem>
        </>
      )}
    </AdjustButtonContainer>
  );
};

export default AdjustButton;
