import React from "react";
import styled from "styled-components";
import { Col } from "reactstrap";
import { GetUserInformation, SearchUser } from "../../services/ad";
import Center from "../atoms/center";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPerson } from "@fortawesome/free-solid-svg-icons";
import FormField from "../atoms/formField";
import TableRow from "../atoms/tableRow";

const ListContainer = styled.div`
  text-align: left;
  line-height: 18px;
  padding: 0 9px;
  position: relative;
  border-radius: 0 0 10px 10px;
  border: 1px solid #d7d7d7;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  overflow: hidden;
  width: 99%;
  border-top: 0;
  margin-top: -6px;
  margin-bottom: 10px;
  margin-left: 4px;
`;

const StyledCol = styled(Col)`
  cursor: pointer;
  padding: 7px 15px;
  &&:hover {
    background-color: #e9e9e9;
  }
`;

export default class UserSearch extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      users: [],
      loading: true,
      newUser: "",
    };
  }

  handleUserSearch = (event) => {
    const value = event.target.value;
    this.setState({ newUser: value });
    SearchUser(value).then((res) => {
      this.setState({ users: res });
    });
  };

  addUser = (email) => {
    this.setState({ newUser: "", users: [] });
    this.props.addUserAction(email);
  };

  componentDidMount() {
    if (this.props.user !== undefined) {
      GetUserInformation(this.props.user).then((result) => {
        this.setState({ ...result, loading: false });
      });
    }
  }
  componentWillReceiveProps(newProps) {
    if (newProps.user !== this.props.user) {
      GetUserInformation(this.props.user).then((result) => {
        this.setState({ ...result, loading: false });
      });
    }
  }

  render() {
    var userList = this.state.users?.map((item, key) => (
      <TableRow key={key} background>
        <StyledCol xs="12" onClick={() => this.addUser(item.Email)}>
          <FontAwesomeIcon icon={faPerson}></FontAwesomeIcon>&nbsp;
          <b>{item.Name}</b> - {item.Email}
        </StyledCol>
      </TableRow>
    ));
    return (
      <Center {...this.props}>
        <FormField
          title="Last Name or Email to Add User"
          noLabel={true}
          disabled={this.props.readOnly}
          autocomplete="off"
          value={this.state.newUser}
          onChange={this.handleUserSearch}
          name="newUser"
        ></FormField>
        <ListContainer>{userList}</ListContainer>
      </Center>
    );
  }
}
