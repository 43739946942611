import React from "react";
import Button from "../../components/atoms/button";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ifProp } from "styled-tools";

const StyledButton = styled(Button)`
  width: 100%;
  font-size: ${ifProp("lg", "22px", "13px")};
  margin-bottom: 10px;
  white-space: normal;
  background-color: #e1f5fd;
  text-align: left;
  position: relative;
  color: #0a54a5;
  text-align: left;
  padding: 8px 18px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  justify-content: start;
  white-space: nowrap;
  border-radius: 4px;
  background-image: none;
  &:hover {
  }
  &:hover a {
    color: white;
  }
  &:hover a:hover {
    color: #eee;
  }
  @media screen and (max-width: 768px) {
    font-size: 12px;
    text-align: center;
    border-radius: 0px;
    margin-bottom: 0;
    padding: 10px 0 !important;
    display: block;
  }
`;
const StyledActiveButton = styled(StyledButton)`
  background-color: #0a54a5 !important;
  color: white !important;
  a {
    color: white;
  }

  @media screen and (max-width: 768px) {
    display: block !important;
    width: 100%;
  }
`;

const Text = styled.span`
  @media screen and (max-width: 768px) {
    display: block !important;
    width: 100%;
  }
`;

const StyledPreIcon = styled(FontAwesomeIcon)`
  font-size: 22px;
  margin-right: 5px;
  @media screen and (max-width: 768px) {
    display: block !important;
    font-size: 16px;
    width: 100%;
  }
`;

const TabButton = ({ ...props }) => {
  if (props.isActive) {
    return (
      <StyledActiveButton className="tabBt" {...props}>
        {props.icon && <StyledPreIcon icon={props.icon}></StyledPreIcon>}
        <Text>{props.children}</Text>
      </StyledActiveButton>
    );
  } else {
    return (
      <StyledButton className="tabBt" {...props}>
        {props.icon && <StyledPreIcon icon={props.icon}></StyledPreIcon>}
        <Text>{props.children}</Text>
      </StyledButton>
    );
  }
};

export default TabButton;
