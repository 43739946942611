import React from "react";
import { Row, Col } from "reactstrap";
import { getUserInfo, isBrowser } from "../../services/auth";
import Center from "../atoms/center";
import FormHeader from "../atoms/formHeader";
import Loading from "../atoms/loading";
import Button from "../atoms/button";
import FormFieldTextArea from "../atoms/formFieldTextArea";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSave, faStickyNote } from "@fortawesome/free-solid-svg-icons";
import { GetBlogNotes, SaveBlogNote } from "../../services/blog";
import styled from "styled-components";
import { toast } from "react-toastify";
import BlogItem from "../molecules/blogItem";

const SaveButton = styled(Button)`
  width: 100%;
  height: 100%;
  font-size: 22px;
  border: 0;
  border-radius: 0px 3px 3px 0;
`;

const Container = styled.div`
  min-height: 175px;
`;

export default class Blog extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      sn: props.sn,
      notes: [],
      loading: true,
      disabled: false,
      newNote: "",
    };
    this.saveNote = this.saveNote.bind(this);
    this.refreshNotes = this.refreshNotes.bind(this);
  }
  componentDidMount() {
    this.refreshNotes();
  }

  componentWillReceiveProps(newProps) {
    if (newProps.sn !== this.props.sn) {
      this.setState({ sn: newProps.sn }, () => {
        this.refreshNotes();
      });
    }
  }

  refreshNotes() {
    GetBlogNotes(this.state.sn, this.props.blogType).then((res) => {
      this.setState({ notes: res.data, loading: false }, () => {
        if (this.props.updateCount) {
          this.props.updateCount(res.data.length);
        }
      });
    });
  }

  handleUserInput = (event) => {
    if (!this.state.readOnlyAccess) {
      const name = event.target.name;
      const value = event.target.value;
      this.setState({ [name]: value });
    }
  };

  saveNote = () => {
    var payload = {
      SerialNumber: this.state.sn,
      BlogType: this.props.blogType,
      Notes: this.state.newNote,
      CreatedBy: getUserInfo().Email,
    };

    SaveBlogNote(payload).then((res) => {
      if (res.data) {
        toast.success("Note Added/Updated Successfully");
        this.setState({ newNote: "", disabled: false });
        this.refreshNotes();
      } else {
        toast.error("Column Log Failed");
      }
    });
  };

  onEnterPress = (e) => {
    if (this.state.newNote.trim().length === 0) return;
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      var matches = this.state.newNote.match(/\bhttps?:\/\/\S+/gi);
      if (matches == null || matches.length === 0) {
        this.saveNote();
      } else {
        if (isBrowser()) {
          window.location.replace(matches[0]);
        }
      }
    }
  };

  render() {
    const allNotes = this.state.notes?.map((item, key) => (
      <BlogItem
        readOnly={this.props.readOnly}
        sn={this.state.sn}
        callback={this.refreshNotes}
        key={key}
        item={item}
        type={2}
      ></BlogItem>
    ));

    return (
      <Container>
        {this.props.title && (
          <Row>
            <Col>
              <FormHeader>
                <FontAwesomeIcon icon={faStickyNote}></FontAwesomeIcon>
                &nbsp;&nbsp;{this.props.title}
              </FormHeader>
            </Col>
          </Row>
        )}
        {this.props.sn !== "N/A" && (
          <>
            {this.state.loading && <Loading size="lg"></Loading>}
            {!this.state.loading && (
              <>
                {!this.props.readOnly && (
                  <Row>
                    <Col xs="12" md="9">
                      <FormFieldTextArea
                        noLabel={true}
                        title="New Note"
                        value={this.state.newNote}
                        onChange={this.handleUserInput}
                        onKeyDown={this.onEnterPress}
                        disabled={this.props.disabled}
                        name="newNote"
                        maxLength="1000"
                      ></FormFieldTextArea>
                    </Col>
                    <Col style={{ marginBottom: "20px" }} xs="12" md="3">
                      <SaveButton
                        disabled={
                          this.state.newNote.trim() === "" ||
                          this.state.disabled
                            ? true
                            : false
                        }
                        onClick={() => this.saveNote()}
                      >
                        <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>&nbsp;
                        Save
                      </SaveButton>
                    </Col>
                  </Row>
                )}
                {this.state.notes.length === 0 && (
                  <Center>
                    No notes have been recorded
                    <br />
                    <br />
                  </Center>
                )}
                {allNotes}
              </>
            )}
          </>
        )}
      </Container>
    );
  }
}
