import React from "react";
import styled from "styled-components";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const StyledFormContainer = styled.div`
  width: 100%;
  margin: auto;
  background-color: #ebfbf9;
  padding: 6px 15px 7px 15px;
  border: 1px solid #b3b3b3;
  margin-bottom: 10px;
  border-top: 4px solid #004e8e;
  border-radius: 5px;
  box-shadow: rgb(0 0 0 / 16%) 0px 1px 4px;
  position: relative;
`;
const FilterTitle = styled.span`
  display: block;
  font-size: 13px;
  margin-bottom: 2px;
  color: #9a9a9a;
`;

const FilterContainer = ({ ...props }) => {
  return (
    <StyledFormContainer {...props}>
      <FilterTitle>
        <FontAwesomeIcon icon={faFilter}></FontAwesomeIcon>&nbsp;{props.nameItem ? props.nameItem : "Search Results Filters"}:
      </FilterTitle>
      {props.children}
    </StyledFormContainer>
  );
};

export default FilterContainer;
