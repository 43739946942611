import React from "react";
import LayoutForm from "../../components/layoutForm";
import Seo from "../../components/atoms/seo";
import { graphql } from "gatsby";
import { withTranslation } from "react-i18next";
import IncidentReportForm from "../../components/organisms/forms/incidentReport";

class IncidentReportPage extends React.Component {
  render() {
    return (
      <LayoutForm
        headerTitle={
          <>
            EHS&nbsp;<span style={{ color: "#707070" }}>Incident Portal</span>
          </>
        }
      >
        <Seo
          title={this.props.t("EHS Incident Portal")}
          keywords={[`PerkinElmer`]}
        />
        <IncidentReportForm></IncidentReportForm>
      </LayoutForm>
    );
  }
}
export default withTranslation()(IncidentReportPage);
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        ns: { in: ["common", "incident-report"] }
        language: { eq: $language }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
