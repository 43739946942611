import axios from "axios";
// eslint-disable-next-line
var apiUrl = `${process.env.GATSBY_API_URL}` + "/api/";

export const SaveBlogNote = (props) => {
  var searchResults = axios
    .post(`${apiUrl}blog/SaveBlogNote`, props, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { err: e };
    });
  return searchResults;
};

export const GetBlogNotes = (sn, type) => {
  var searchResults = axios
    .get(`${apiUrl}blog/GetBlogNotes?sn=${sn}&type=${type}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { err: e };
    });
  return searchResults;
};

export const DeleteBlogNote = (id) => {
  var searchResults = axios
    .get(`${apiUrl}blog/DeleteBlogNote/${id}`, {
      headers: { "Content-Type": "application/json; charset=utf-8" },
    })
    .then((response) => {
      return response;
    })
    .catch((e) => {
      return { err: e };
    });
  return searchResults;
};
