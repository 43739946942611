import React from "react";
import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFile,
  faImage,
  faInfoCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import {
  GetAttachments,
  DownloadDocument,
  RemoveFile,
  UploadFile,
} from "../../services/formAttachment";
import { saveAs } from "file-saver";
import Label from "../atoms/label";
import { Col, Row } from "reactstrap";
import { toast } from "react-toastify";
import Dropzone from "../molecules/dropzone";
import Loading from "../atoms/loading";
import Center from "../atoms/center";
import { getUserInfo } from "../../services/auth";

const FileBox = styled.div`
  border: 2px solid #0039a6;
  padding: 10px;
  background: #ecf2fe;
  padding: 7px 21px;
  color: #0039a6;
  border-radius: 7px;
  background-image: none;
  height: 100%;
  position: relative;
  &&:hover {
    background: #cdddff;
  }
`;

const FileLink = styled.span`
  cursor: pointer;
  &:hover {
    color: blue;
  }
`;

const StyledImage = styled.img`
  border-radius: 5px;
  max-height: 150px;
  cursor: pointer;
  margin-bottom: 0;
`;
export default class FormFieldUploadFile extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      uploadFiles: [],
      uploading: false,
      uploadProgress: {},
      successfulUpload: false,
      loading: true,
    };

    this.onFilesAdded = this.onFilesAdded.bind(this);
    this.handleProgress = this.handleProgress.bind(this);
    this.getAttachments();
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.id !== this.props.id) {
      if (nextProps.id === "") {
        this.setState({ uploadFiles: [] });
      } else {
        this.getAttachments(nextProps.id);
      }
    }
  }

  getAttachments(id) {
    var newID = id ? id : this.props.id;
    if (newID !== 0) {
      this.setState({ loading: true });
      GetAttachments({ formID: newID, formType: this.props.formType })
        .then((res) => {
          this.setState({ uploadFiles: res.data, loading: false });
          if (this.props.callbackFiles) {
            this.props.callbackFiles(
              this.props.formType,
              this.state.uploadFiles
            );
          }

          if (this.props.callback) {
            if (this.props.name) {
              this.props.callback(
                this.props.formType,
                res.data.length > 0,
                this.props.name
              );
            } else {
              this.props.callback(this.props.formType, res.data.length > 0);
            }
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      this.setState({ loading: false });
    }
  }

  handleProgress = (event, file) => {
    const copy = { ...this.state.uploadProgress };
    copy[file.name] = {
      state: "pending",
      percentage: (event.loaded / event.total) * 100,
    };
    this.setState({ uploadProgress: copy });
  };

  downloadFile = (file) => {
    DownloadDocument({ fileId: file.AttachmentID })
      .then((res) => {
        let doc = new Blob([res.data], { type: file.FileType });
        saveAs(doc, file.FileName);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  onFilesAdded = (files) => {
    this.setState(
      (prevState) => ({
        uploadFiles: prevState.uploadFiles.concat(files),
      }),
      () => {
        this.uploadFiles(files);
      }
    );
  };

  uploadFiles = async () => {
    this.setState({ uploadProgress: {}, uploading: true });
    const promises = [];
    this.state.uploadFiles.forEach((file) => {
      if (file.size / 1024 / 1024 <= (this.props.size || 10)) {
        promises.push(
          UploadFile({
            file: file,
            handleProgress: this.handleProgress,
            formID: this.props.id,
            userId: getUserInfo() !== null ? getUserInfo().Name : "",
            formType: this.props.formType,
          })
        );
      } else if (!file.AttachmentID) {
        toast.error(
          "The file is too large. The max file size is " +
            (this.props.size?.toString() || "10") +
            "MB."
        );
      }
    });
    try {
      await Promise.all(promises);
      this.setState({ successfulUpload: true, uploading: false });
      this.getAttachments();
    } catch (e) {
      console.log(e);
      this.setState({ successfulUpload: true, uploading: false });
    }
  };

  renderProgress = (file) => {
    if (this.state.uploading || this.state.successfulUpload) {
      return <Loading abs={true}></Loading>;
    }
  };

  removeFile = (id) => {
    this.setState({ loading: true });
    RemoveFile({
      fileId: id,
      user: getUserInfo() !== null ? getUserInfo().Email : "",
    })
      .then((res) => {
        toast.success(res);
        this.getAttachments();
      })
      .catch((err) => {
        console.log(err);
      });
  };

  render() {
    return (
      <>
        <Col xs="12" sm="12">
          <Label>
            {this.props.title ? this.props.title : "Upload Documents"}{" "}
          </Label>
        </Col>
        {!this.props.readOnly && (
          <Col xs="12" sm="12" md="12">
            <Dropzone
              onFilesAdded={this.onFilesAdded}
              backColor="#254F9E"
              helperText={"This is a test"}
            ></Dropzone>
          </Col>
        )}
        {this.state.loading === true && <Loading size="lg"></Loading>}
        {this.props.id !== 0 && this.state.loading === false && (
          <Row>
            {this.state.uploadFiles?.length === 0 && (
              <Col>
                <br />
                <Center>
                  <i>
                    <FontAwesomeIcon icon={faImage}></FontAwesomeIcon>&nbsp; No
                    Attachments Added
                  </i>
                </Center>
                <br />
              </Col>
            )}
            {this.state.uploadFiles.map((file, index) => {
              return (
                <Col
                  xs="12"
                  md="6"
                  lg="4"
                  key={index}
                  style={{ marginBottom: "10px" }}
                >
                  <FileBox>
                    <FontAwesomeIcon icon={faFile}></FontAwesomeIcon>
                    &nbsp;&nbsp;
                    <FileLink
                      onClick={() => this.downloadFile(file)}
                      id={index}
                    >
                      {file.FileName}
                    </FileLink>
                    {!this.props.readOnly && (
                      <FileLink
                        style={{
                          position: "absolute",
                          top: "3px",
                          right: "4px",
                          cursor: "pointer",
                        }}
                        onClick={() => this.removeFile(file.AttachmentID)}
                        id={index}
                      >
                        <FontAwesomeIcon icon={faTrashAlt}></FontAwesomeIcon>
                      </FileLink>
                    )}
                    <Center>
                      {file.Data && file.ContentType.startsWith("image") && (
                        <>
                          <StyledImage
                            onClick={() => this.downloadFile(file)}
                            src={"data:image/png;base64," + file.Data}
                            alt="Base64 Image"
                          />
                          <br />
                          <i style={{ fontSize: "12px", color: "#003a79" }}>
                            <FontAwesomeIcon
                              icon={faInfoCircle}
                            ></FontAwesomeIcon>
                            &nbsp;Click Image to Download
                          </i>
                        </>
                      )}
                    </Center>
                    <div>{file.FileName ? "" : this.renderProgress(file)}</div>
                  </FileBox>
                </Col>
              );
            })}
          </Row>
        )}
      </>
    );
  }
}
