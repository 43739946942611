import React from "react";
import styled from "styled-components";
import { Row, Col } from "reactstrap";
import { GetChangeLog } from "../../services/incidentReport";
import Loading from "../atoms/loading";
import {
  faCalendarAlt,
  faHistory,
  faRandom,
  faRefresh,
  faUser,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import TableRow from "../atoms/tableRow";
import TableRowHeader from "../atoms/tableRowHeader";
import Pagination from "../atoms/pagination";
import LocalDate from "../atoms/localDate";
import FormHeader from "../atoms/formHeader";

const StyledRefresh = styled(FontAwesomeIcon)`
  font-size: 30px;
  margin-left: 15px;
  float: right;
  cursor: pointer;
  &:hover {
    color: #0a54a5;
  }
`;

export default class ChangeLog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      history: [],
      loading: true,
      activePage: 1,
      itemsPerPage: 15,
      total: 0,
    };

    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    this.refreshForm(this.state.itemsPerPage, 0);
  }

  componentWillReceiveProps(newProps) {
    if (newProps.instrumentSN !== this.props.instrumentSN) {
      this.refreshForm(this.state.itemsPerPage, 0);
    }
  }

  refreshForm(limit = 15, offset = 0) {
    this.setState({ history: [], loading: true }, () => {
      GetChangeLog(this.props.guid, limit, offset).then((result) => {
        if (result.data.HasErrors) {
        } else {
          this.setState({
            history: result.data.Data,
            loading: false,
            total: result.data.TotalCount,
          });
        }
      });
    });
  }

  handlePageChange(pageNumber) {
    this.setState({ activePage: Number(pageNumber) }, () => {
      this.refreshForm(
        this.state.itemsPerPage,
        Number(pageNumber) !== 1
          ? (Number(pageNumber) - 1) * this.state.itemsPerPage
          : 0
      );
    });
  }

  render() {
    const history = this.state.history.map((item, key) => (
      <TableRow key={key}>
        <Col xs="12" md="7">
          <FontAwesomeIcon icon={faRandom}></FontAwesomeIcon>&nbsp;
          <b>{item.Field}</b> was changed from <b>{item.OldValue}</b> to{" "}
          <b>{item.NewValue}</b>
        </Col>
        <Col xs="6" md="3">
          {item.CreatedBy || "N/A"}
        </Col>
        <Col xs="6" md="2">
          <LocalDate>{item.CreatedOn}</LocalDate>
        </Col>
      </TableRow>
    ));
    return (
      <>
        <Row>
          <Col>
            <FormHeader>
              <FontAwesomeIcon icon={faHistory}></FontAwesomeIcon>&nbsp;
              {this.props.newTitle ? (
                <>{this.props.newTitle}</>
              ) : (
                <>Change Log for {this.props.incident}</>
              )}
              {this.state.total > 0 && <span> ({this.state.total})</span>}
              <StyledRefresh
                icon={faRefresh}
                onClick={() => {
                  this.refreshForm(this.state.itemsPerPage, 0);
                }}
              ></StyledRefresh>
            </FormHeader>
          </Col>
        </Row>
        <div style={{ margin: "0 12px" }}>
          <TableRowHeader>
            <Col sm="6" md="7">
              <FontAwesomeIcon icon={faRandom}></FontAwesomeIcon>
              &nbsp;&nbsp;Change
            </Col>
            <Col sm="3" md="3" className="d-none d-md-block">
              <FontAwesomeIcon icon={faUser}></FontAwesomeIcon>
              &nbsp;&nbsp;Changed By
            </Col>
            <Col sm="3" md="2" className="d-none d-md-block">
              <FontAwesomeIcon icon={faCalendarAlt}></FontAwesomeIcon>
              &nbsp;&nbsp;Changed On
            </Col>
          </TableRowHeader>
          {this.state.loading && (
            <Row>
              <Loading size="lg"></Loading>
            </Row>
          )}
          <Row className="stripe">
            <Col>{history}</Col>
          </Row>
          {this.state.total > 0 && (
            <Row>
              <Pagination
                activePage={this.state.activePage ? this.state.activePage : 1}
                itemsCountPerPage={this.state.itemsPerPage}
                totalItemsCount={this.state.total}
                pageRangeDisplayed={10}
                onChange={this.handlePageChange}
              />
            </Row>
          )}
          {this.state.total === 0 && !this.state.loading && (
            <TableRow>
              <Col>No Changes have been found.</Col>
            </TableRow>
          )}
        </div>
      </>
    );
  }
}
